<template>
  <div class="container">    
     <!-- 使用 TheHeader 组件 -->
    <the-header></the-header>
    <div class="main-content">
      <div class="sidebar">
        <!-- Sidebar -->
        <van-sidebar v-model="activeKey" :style="{ width: '79%'}">
          <van-sidebar-item v-for="item in sidebarItems" :key="item.name" :title="item.title" :to="item.to" />
        </van-sidebar>
      </div>
      <div class="content">
        <!-- Content -->
        <div class="content-list">
          <el-card v-for="data in datas" :key="data.id" class="content-item" :style="{ width: '100%' }" :body-style="{ padding: '20px' }">
            <div class="title-and-status">
              <span class="title">{{ data.title }}</span>
              <el-tag  v-if="data.is_closed === 1">已结单</el-tag>
              <el-tag  v-else>考评中</el-tag>
   
            </div>
            <div class="time">
              <p><strong>发布时间：</strong>{{ data.released_at }}</p>
              <p><strong>结单时间：</strong>{{ data.close_at }}</p>              
            </div>
            <hr>
            <div class="description">
              <p>{{ data.description }}              
                <el-button v-if="data.is_closed === 1" type="warning"  class="right-button">已结单</el-button>
                <el-button v-else type="primary"  class="right-button" @click="goToAssess(data.id)">去考评</el-button>
              </p>
              
            </div>
          </el-card>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import { ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import TheHeader from './TheHeader.vue'; // 导入 TheHeader 组件
import { onMounted } from 'vue';
import useUserStore from '@/stores/user';
import api from '@/api';

export default {
  components: {
    TheHeader, // 注册 TheHeader 组件   
  },
  setup() {
    const userStore = useUserStore();
    onMounted(() => {
      document.title = '怡养健康人力资源管理系统';
      fetchData();
      userStore.director_id = localStorage.getItem('director_id');
      userStore.department_id = localStorage.getItem('department_id');
    });    
    const datas = ref([]);
    const activeKey = ref(0); // Control the active sidebar item
    const route = useRoute();
    const fetchData = async () => {
      try {
        api.get('/assess/orders/', {
          params: {
            department_id: userStore.department_id,
            user_id: userStore.director_id
          }
        }).then((res) => {
          console.log(res.data.data);          // 处理响应数据
          console.log(userStore.director_id);
          datas.value = res.data.data; // 将数据赋值给 datas
          //赋值给datas 
        });

      } catch (error) {
        // 处理错误
        console.error('Error fetching data:', error);
      }
    };

    const sidebarItems = [
      { name: 'home', title: '本月考评', to: { name: 'home' } },
      { name: 'assesslist', title: '绩效汇总', to: { name: 'assesslist' } },
      { name: 'lastmonthfee', title: '科室费用', to: { name: 'lastmonthfee' } }, 
      { name: 'staffs', title: '科室职工', to: { name: 'staffs' } },
      { name: 'assesshistory', title: '考评记录', to: { name: 'assesshistory' } },
      { name: 'clocklist', title: '考勤记录', to: { name: 'clocklist' } },
      { name: 'standardspage', title: '考评标准', to: { name: 'standardspage' } },
      
      //{ name: 'applications', title: '请假申请', to: { name: 'applications' } },
    ];

    // Set the active sidebar item based on the current route
    activeKey.value = route.meta.sidebarKey || 0;

    // Watch for route changes and update the active sidebar item
    watch(route, (newRoute) => {
      activeKey.value = newRoute.meta.sidebarKey || 0;
    });

    return {
      activeKey,
      userStore,
      sidebarItems,
      datas // 将数据作为响应式数据返回，以便在模板中使用
 
    };
  },
  methods: {
    goToAssess(id) {
      // 使用 router.push 方法跳转到详情页
      // 假设详情页的路由是 { name: 'detail' }
      // 您可以根据实际情况调整路由名称和参数
      this.$router.push({ name: 'detail', params: { id: id } });
    }
  }
};
</script>

<style>
 .container {
    width: 85%;
    min-width:1230px;
    margin: 0 auto;
    /* 水平居中 */
  }

  .main-content {
    display: flex;
  }

  .sidebar {
    width: 218px;
    background-color: #fff;
  }

  .content {
    flex: 1;
    /* 让内容区域占据剩余空间 */
    padding: 20px;
    /* 添加一些内边距 */
    background-color: #fff;
  }

  .van-sidebar {
    width: 100%;
  }

  .van-sidebar-item {
    border-bottom: 1px solid #eaeaea;

  }

  .van-sidebar-item:last-child {
    border-bottom: none;
  }

  .van-sidebar-item--selected {
    color: #3f51b5;
    background-color: #e8eaf6;
  }
  .content-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.content-item {
  width: calc(33.33% - 20px); /* Adjust width based on your layout */
}

.title-and-status {
  display: flex;
  justify-content: space-between;
  margin-bottom:11px;
}

.title {
  font-weight: bold;
  font-size: 18px;
}

.time p {
  margin-bottom: 10px;
}

hr {
  border: none;
  border-top: 1px solid #ccc;
  margin: 20px 0;
}
.right-button {
  float: right; /* 使按钮浮动到右侧 */
}
</style>